import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../util/routes';
import { parse } from '../../util/urlHelpers';

import { Button, InlineTextButton, OutsideClickHandler } from '../';
import DateRangeController from '../FieldDateRangeController/DateRangeController';
import LocationAutocompleteInputImpl from '../LocationAutocompleteInput/LocationAutocompleteInputImpl';
import { createSearchParams } from './helpers';

import { SearchIcon } from './SearchIcon';
import css from './SearchFormTopbar.module.css';
import constructionSiteImg from './assets/construction-site-illustration.png';
import criticalInfrastructureImg from './assets/critical-infrastructure-illustration.png';
import solarParksImg from './assets/solar-parks-illustration.png';
import windFarmsImg from './assets/wind-farms-illustration.png';
import flagDE from './assets/de.svg';
import flagAT from './assets/at.svg';
import flagCH from './assets/ch.svg';

const IconArrowPrev = ({ isDisabled }) => {
  if (isDisabled) return <></>;
  return (
    <svg
      class="DateRangeController_arrowIcon__O6s1B"
      width="9"
      height="13"
      viewBox="0 0 9 13"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#ff0953"
    >
      <path
        d="M1.195 6.03c-.26.26-.26.68 0 .94l5.333 5.335c.262.26.683.26.943 0 .262-.26.262-.683 0-.943L2.61 6.5l4.86-4.862c.262-.26.262-.683 0-.943-.26-.26-.68-.26-.942 0L1.195 6.03z"
        fill-rule="evenodd"
        fill="#ff0953"
      ></path>
    </svg>
  );
};

const KeywordAutocompletePanel = ({ placeholderId,onChange, value, ...restProps }) => {
  const intl = useIntl();

  const handleChange = e => {
    onChange(e.target.value);
  };

  return (
    <input
      className={css.keywordInput}
      value={value}
      onChange={handleChange}
      type="text"
      placeholder={intl.formatMessage({ id: placeholderId ?? 'SearchFormTopbar.keywords.label' })}
      autoComplete="off"
      {...restProps}
    />
  );
};

const LocationAutocompletePanel = ({ onChange, autoFocus, value }) => {
  const intl = useIntl();

  return (
    <div className={css.locationAutocompletePanel}>
      <LocationAutocompleteInputImpl
        className={css.locationAutocomplete}
        iconClassName={css.locationAutocompleteIcon}
        inputClassName={css.locationAutocompleteInput}
        predictionsClassName={css.locationAutocompletePredict}
        autoFocus={autoFocus}
        placeholder={intl.formatMessage({ id: 'SearchFormTopbar.where.label' })}
        input={{
          value: value,
          name: 'location',
          onChange,
          onFocus: () => null,
          onBlur: () => null,
        }}
      />
    </div>
  );
};

const SelectDatePanel = ({
  onSelect,
  startDate,
  endDate,
  onApply,
  onCancel,
  onReset,
  onScroll,
  ...restProps
}) => {
  const checkIsOutsideRange = day => {
    return moment(day).diff(moment().set({ h: 0, m: 0, s: 0 }), 'minute') < 0;
  };

  return (
    <div className={css.selectDatePanel} onScroll={onScroll}>
      <DateRangeController
        onChange={onSelect}
        value={{ startDate, endDate }}
        isOutsideRange={checkIsOutsideRange}
        numberOfMonths={2}
        {...restProps}
      />
    </div>
  );
};

const SearchFormTopbar = () => {
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const location = useLocation();
  const intl = useIntl();

  const [keywordsValue, setKeywordsValue] = useState('');
  const [areaValue,setAreaValue] = useState(null);
  const [whereValue, setWhereValue] = useState(null);
  const [whenValue, setWhenValue] = useState(null);
  const [isOpenPanel, setIsOpenPanel] = useState(false);
  const [isOpenAreaPanel, setIsOpenAreaPanel] = useState(false);
  const [isOpenLocationPanel, setIsLocationPanel] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(moment(whenValue?.startDate ?? undefined));
  const [isPrevDisabled, setIsPrevMonthDisabled] = useState(true);
  const [numberOfMonths, setNumberOfMonths] = useState(2);
  const [countryValue, setCountryValue] = useState(null);
  const [countryLabel, setCountryLabel] = useState('');
  const [isMobile, setIsMobile] = useState(
    typeof window !== 'undefined' && window?.innerWidth <= 630
  );

  const handleLoadMoreMonths = () => {
    setNumberOfMonths(prev => prev + 2);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        const mobileView = window.innerWidth <= 630;
        setIsMobile(mobileView);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  useEffect(() => {
    setIsPrevMonthDisabled(currentMonth.isSame(moment(), 'month'));
  }, [currentMonth]);

  useEffect(() => {
    if (isOpenPanel === 1) {
      setCurrentMonth(moment(whenValue?.startDate ?? undefined));
    }
  }, [isOpenPanel]);

  const handleNextMonthClick = () => {
    setCurrentMonth(currentMonth.clone().add(1, 'month'));
  };

  const handlePrevMonthClick = () => {
    setCurrentMonth(currentMonth.clone().subtract(1, 'month'));
  };

  useEffect(() => {
    const { keywords, address, bounds, dates } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    if (keywords) {
      setKeywordsValue(keywords);
    }

    if (dates && dates.split(',')[0] && dates.split(',')[1]) {
      setWhenValue({
        startDate: new Date(dates.split(',')[0]),
        endDate: new Date(dates.split(',')[1]),
      });
    }

    if (address && bounds) {
      setWhereValue({
        search: address,
        selectedPlace: {
          address,
          bounds,
        },
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const { dates } = parse(location.search);

    if (dates && dates.split(',')[0] && dates.split(',')[1]) {
      setWhenValue({
        startDate: new Date(dates.split(',')[0]),
        endDate: new Date(dates.split(',')[1]),
      });
    }
  }, [location.search]);

  const handleOpenPanel = index => () => {
    if (isOpenPanel === index) {
      handleCancelDates();
    }
    setIsOpenPanel(isOpenPanel === index ? false : index);
  };

  const handleSetKeyword = value => {
    setKeywordsValue(value);
  };

  const handleSetPrediction = value => {
    const isCurrentLocation =
      !value?.search && !value?.selectedPlace?.address && !!value?.selectedPlace?.bounds;
    setWhereValue({
      ...value,
      ...(value?.name === 'location' && !!value?.onChange
        ? {
            search: intl.formatMessage({ id: 'SearchFormTopbar.where.loadingCurrentLocation' }),
            selectedPlace: {
              address: intl.formatMessage({ id: 'SearchFormTopbar.where.loadingCurrentLocation' }),
            },
          }
        : {}),
      ...(isCurrentLocation
        ? {
            search: intl.formatMessage({ id: 'SearchFormTopbar.where.currentLocation' }),
            selectedPlace: {
              ...(value?.selectedPlace || {}),
              address: intl.formatMessage({ id: 'SearchFormTopbar.where.currentLocation' }),
            },
          }
        : {}),
    });
  };

  const handleApplyDates = () => {
    setIsOpenPanel(false);
    handleSubmit();
  };

  const handleSelectDates = ({ startDate, endDate }) => {
    setWhenValue({
      startDate,
      endDate,
    });
  };

  const handleCancelDates = () => {
    const { dates } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    if (dates && dates.split(',')[0] && dates.split(',')[1]) {
      setWhenValue({
        startDate: new Date(dates.split(',')[0]),
        endDate: new Date(dates.split(',')[1]),
      });
    } else {
      setWhenValue(null);
    }
  };

  const handleResetDates = e => {
    e?.stopPropagation?.();
    setWhenValue(null);
  };

  const handleSubmit = () => {
    const searchParams = createSearchParams({ whereValue, whenValue, keywordsValue, areaValue, countryValue });
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };

  const startDateLabel = whenValue?.startDate
    ? moment(whenValue.startDate).format('MMM DD.')
    : null;
  const endDateLabel = whenValue?.endDate ? moment(whenValue.endDate).format('MMM DD.') : null;

  const isOutsideRange = day => {
    const minimumDate = moment();
    return day.isBefore(minimumDate, 'day');
  };

  return (
    <div className={css.root}>
      <div className={css.searchBox}>
        <div className={css.searchBoxItem}>
          <OutsideClickHandler
            className={css.searchBoxItemOutsideClick}
            onOutsideClick={() => {
              if (isOpenAreaPanel) {
                setIsOpenAreaPanel(false);
              }
            }}
          >
            <div className={css.searchBoxItemContent}>
              <KeywordAutocompletePanel
                onFocus={() => setIsOpenAreaPanel(true)}
                onChange={handleSetKeyword}
                value={keywordsValue}
                style={{ paddingRight: '10px' }}
              />
              {keywordsValue ? (
                <FontAwesomeIcon
                  className={css.keywordCancelIcon}
                  color="#C3C3C3"
                  icon={faClose}
                  onClick={() => {setKeywordsValue(''); setAreaValue(null);} }
                />
              ) : (
                <></>
              )}
              {isOpenAreaPanel && (
                <div className={css.areaSearchDropdown}>
                  <h3 className={css.areaSearchHeading}>
                    <FormattedMessage id="SearchFormTopbar.area.heading" />
                  </h3>
                  <div className={css.areaSearchSegment}>
                    {[
                      {
                        textId: 'SearchFormTopbar.area.constructionSite',
                        img: constructionSiteImg,
                        imgAlt: 'construction-site',
                        area:'constructionsites',
                      },
                      {
                        textId: 'SearchFormTopbar.area.criticalInfrastructure',
                        img: criticalInfrastructureImg,
                        imgAlt: 'critical-infrastructure',
                        area:'criticalinfrastructures',
                      },
                      {
                        textId: 'SearchFormTopbar.area.solarparks',
                        img: solarParksImg,
                        imgAlt: 'solar-parks',
                        area:'solarparks',
                      },
                      {
                        textId: 'SearchFormTopbar.area.windFarms',
                        img: windFarmsImg,
                        imgAlt: 'wind-farms',
                        area:'windfarms',
                      },
                    ].map(({ textId, img, imgAlt ,area}) => (
                      <div key={area}
                        className={css.areaSearchCard}
                        onClick={() => {
                          setKeywordsValue(intl.formatMessage({ id: textId }));
                          setAreaValue(area);
                          setIsOpenAreaPanel(false)
                        }}
                      >
                        <div className={css.areaSearchImgWrapper}>
                          <img className={css.areaSearchImg} src={img} alt={imgAlt} />
                        </div>
                        <div className={css.areaSearchText}>
                          <FormattedMessage id={textId} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </OutsideClickHandler>
        </div>
        <div className={css.searchBoxItem}>
          <OutsideClickHandler
            className={css.searchBoxItemOutsideClick}
            onOutsideClick={() => {
              if (isOpenPanel === 1) {
                setIsOpenPanel(false);
              }
            }}
          >
            <div
              className={classNames(css.searchBoxItemContent, css.datesRangeItem)}
              onClick={handleOpenPanel(1)}
            >
              <div
                className={classNames(css.searchBoxItemInput, css.datesRangeLabel, {
                  [css.datesRangePlaceholder]: !whenValue,
                })}
              >
                {whenValue ? (
                  <>
                    <div className={css.dateRangeLabelWrapper}>
                      <div className={css.dateRangeLabelWrapperInner}>
                        <div className={css.datesRangeLabelLines}>{startDateLabel}</div>
                        <div className={css.hideOnDesktop}>&nbsp;-&nbsp;</div>
                        <div className={css.datesRangeLabelLines}>{endDateLabel}</div>
                      </div>
                      <FontAwesomeIcon
                        icon={faClose}
                        onClick={handleResetDates}
                        className={css.datesRangeCancelIcon}
                        color="#C3C3C3"
                      />
                    </div>
                  </>
                ) : (
                  <span className={css.placeholder}>
                    {intl.formatMessage({ id: 'SearchFormTopbar.when.label' })}
                  </span>
                )}
              </div>
            </div>
            {isOpenPanel === 1 && (
              <SelectDatePanel
                onSelect={handleSelectDates}
                startDate={whenValue?.startDate}
                endDate={whenValue?.endDate}
                onApply={handleApplyDates}
                onCancel={handleCancelDates}
                onReset={handleResetDates}
                minDate={moment(new Date())}
                onPrevMonthClick={handlePrevMonthClick}
                onNextMonthClick={handleNextMonthClick}
                navPrev={<IconArrowPrev isDisabled={isPrevDisabled} />}
                numberOfMonths={isMobile ? numberOfMonths : 2}
                orientation={isMobile ? 'verticalScrollable' : 'horizontal'} // Stack months vertically
                hideKeyboardShortcutsPanel={true} // Hide keyboard shortcuts for mobile
                isOutsideRange={isOutsideRange}
                onScroll={e => {
                  const { scrollTop, clientHeight, scrollHeight } = e.target;
                  if (scrollTop + clientHeight >= scrollHeight - 250) {
                    handleLoadMoreMonths();
                  }
                }}
              />
            )}
          </OutsideClickHandler>
        </div>
        <div className={css.searchBoxItem}>

        <OutsideClickHandler
            className={css.searchBoxItemOutsideClick}
            onOutsideClick={() => {
              if (isOpenLocationPanel) {
                setIsLocationPanel(false);
              }
            }}>
            <div className={css.searchBoxItemContent}>
              <KeywordAutocompletePanel
                onFocus={() => setIsLocationPanel(true)}
                value={countryLabel} readOnly
                style={{ paddingRight: '10px' }}
                placeholderId='SearchFormTopbar.country.label'
              />
              {countryLabel ? (
                <FontAwesomeIcon
                  className={css.keywordCancelIcon}
                  color="#C3C3C3"
                  icon={faClose}
                  onClick={() => { setCountryLabel(''); setCountryValue(null); }}
                />
              ) : (
                <></>
              )} {isOpenLocationPanel && (
                <div className={css.areaSearchDropdown}>
                  {/*                   <h3 className={css.areaSearchHeading}>
                    <FormattedMessage id="SearchFormTopbar.area.heading" />
                  </h3> */}
                  <div >
                    {[
                      {
                        textId: 'SearchFormTopbar.where.de',
                        img: flagDE,
                        imgAlt: 'deliver-germany',
                        country: 'DE',
                      },
                      {
                        textId: 'SearchFormTopbar.where.at',
                        img: flagAT,
                        imgAlt: 'deliver-austria',
                        country: 'AT',
                      },
                      {
                        textId: 'SearchFormTopbar.where.ch',
                        img: flagCH,
                        imgAlt: 'deliver-swiss',
                        country: 'CH',
                      },
                    ].map(({ img, imgAlt, textId, country }) => (
                      <div key={country}
                        className={css.countryItem}
                        onClick={() => {

                          setCountryLabel(intl.formatMessage({ id: textId }));
                          setCountryValue(country);
                          setIsLocationPanel(false);
                        }}
                      >
                        <div className={css.countryImgWrapper} >
                          <img className={css.countryImg} src={img} alt={imgAlt} />
                        </div>

                        <FormattedMessage id={textId} />
                      </div>
                    ))}
                  </div>


                </div>

              )}
            </div>
          </OutsideClickHandler>

      {/*     <div className={css.searchBoxItemContent}>
            <LocationAutocompletePanel onChange={handleSetPrediction} value={whereValue} />
          </div> */}
        </div>

        <Button className={css.searchSubmit} onClick={handleSubmit}>
          <SearchIcon />
          <div className={css.searchText}>
          <FormattedMessage id='SearchFormTopbar.searchBtn' />
          </div>
        </Button>
      </div>
    </div>
  );
};

export default SearchFormTopbar;
